
#root {
  height: 100%;
  max-height: 100vh;
  display: grid;
  grid-template-rows: 1fr 85vh;
}

/* Modular Classes */
.box-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: solid 1px #ecda9d;
}

.split-elements {
  display: flex;
  justify-content: space-between;
}

.flex-horizontal {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.text-center {
  text-align: center;
}

.split-element-horizontal {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.max-size {
  width: 100%;
  height: 100%;
}

/* End Modular Classes */

/* Uncatagorized Styles */
main {
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.app-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}

.link-link {
  text-decoration: none;
  width: 30%;
  height: 15%;
  border-radius: 10px;
  border: solid 1px #ecda9d;
  
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hoverable:hover {
  background-color: #ba272e;
  cursor: pointer;
}

.link-link:hover {
  background-color: #ba272e;
}

.link-text {
  color: black;
}

.classic-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  width: 100%;
}

#player-form {
  height: 50%;
  width: 70%;
}

.form-input-group {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.school-button {
  padding: 25px;
  font-size: 17px;
}

#roster {
  padding: 25px;
  height: 85%;
  overflow-y: scroll;
}

.player-div {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.7;
  top: 0px;
}

.disable {
  pointer-events: none;
}

#confirmation-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  background-color: white;
  padding: 25px;
  border-radius: 25px;
}

#confirmation-box > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-statement {
  font-weight: bold;
  font-size: 18px;
}

.warning-message {
  font-style: italic;
  color: gray;
}

.button-left {
  margin-right: 5px;
}

.button-right {
  margin-left: 5px;
}

.d-none {
  display: none;
}

/* End uncatagorized */

/* Scoreboard styles */
#scoreboard-parent div p {
  border: solid 1px;
  border-radius: 5px;
  font-size: 32px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#scoreboard-parent div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.update-section {
  display: grid;
  grid-template-rows: 50px 1fr;
}

.complete-button {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
}

/* Log Game Styles */
.button-selection {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
}

.game-box {
  border: solid black 1px;
  text-align: center;
  width: 200px;
  height: 200px;
  padding: 10px;
  margin: 5px;

  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.log-icons-div {
  display: flex;
  justify-content: flex-end;
}

/* Form Section Styles */
form {
  background-color: white;
  border-radius: 25px;
  z-index: 1;
}

#form-view {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 85vh;
}

#log-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  height: 80%;
  min-width: 300px;
  width: 70%;
  padding: 20px;
}

.toggle-group > button {
  width: 50%;
}

/* Data Section Styles */
#data-view {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 85vh;
}

#data-box {
  padding: 25px;
  height: 85%;
  min-width: 350px;
  overflow-y: scroll;
}

.action-div {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.penalty-div {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
}

.log-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.big-data-box {
  width: 80%;
}

